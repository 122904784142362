/* :root {
  --primary-color: #5bb462;
  --primary-dark: #4f9c55;
  --secondary-color: #ffea00;
  --secondary-dark:#e3d002;
  --overlay: rgba(0,0,0,.7);
} */

/* index css changes styling globally */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
}

button {
  border: none;
  outline: none;
  background: none;
  color: black !important;
}


section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 120vh;
}

section h2 {
  font-size: 60px;
  margin-top: 60px;
}

/* @media (max-width: 900px) {
  section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80vh
  }
} */