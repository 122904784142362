@import url('https://fonts.googleapis.com/css2?family=Qwitcher+Grypen:wght@700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


.title {
  font-family: 'Qwitcher Grypen';
  font-style: bold;
  font-weight: 700;
  font-size: 70px !important;
  margin-left: 2%;
  text-decoration: none;
  color: black !important;
}

.App {
  text-align: center;
}

/* about section height */
.about {
  height: 400px;
}

/* projects section height */
.projects {
  margin-top: 600px;
  height: 600px;
}

/* contact section height */
.contact {
  margin-top: 600px;
  height: 600px;
}

.header {
  background: white;
  color: black;
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 9;
}

.header {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
  height: 80px;
  padding-top: 10px;
}

.navbar {
  min-height: 40px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  opacity: 100%;
  z-index: 9;
  margin-right: 50px;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: black;
}

.header li {
  font-size: 1rem;
  cursor: pointer;
  color: black;
  background: none;
  border: none;
  position: relative;
}

.header li::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #479B84;
  left: 0;
  /* add highlight link at the bottom */
  bottom: -5px;
  transform: scale(0,1);
  transition: transform 0.50s ease;
  /* changes direction of the underline */
  transform-origin: 0 100%; 
}

.header li:hover::before {
  transform: scale(1,1);
}

.header li:hover::before {
  color: #479B84;
  transition: transform 1s ease;
}

/* responsive navigation bar */
@media (max-width: 1000px) {
  .hamburger {
    display: block;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 80px;
    gap: 0;
    flex-direction: column;
    background-color: white;
    width: 100%;
    text-align: center;
    transition: 0.5s;
    /* background-color: #479B84; */
  }

  .nav-links {
    margin: 16px 0;
  }

  .nav-menu.active {
    left: 0;
  }

}

@media (max-width: 820px) {
  .title {
    font-family: 'Qwitcher Grypen';
    font-style: bold;
    font-weight: 700;
    font-size: 60px !important;
    margin-left: 2%;
    text-decoration: none;
  }
  
}

@media (max-height: 700px) {
  .sectionHeading {
    margin-top: 70px;
  }

  .left {
    float: center;
  }

  .right {
    float: center;
  }


}

@media (max-width: 500px) {

  h3 {
    margin-top: 600px;
  }

  .left {
    width: auto !important;
    height: 500px !important;
    float: center !important;
    margin-top: 600px;
    margin-left: 5px;
  }

 .right {
  display: none;
 }

}

/* for the arrow on the bottom */
.arrow{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.arrow span{
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 3px solid grey;
  border-right: 3px solid grey;
  transform: rotate(45deg);
  margin-top: 550px;
  animation: animate 3.0s ease infinite;
}

@keyframes animate {
  0%{
      opacity: 0;
      transform: rotate(45deg) translate(-20px,-20px);
  }
  50%{
      opacity: 1;
  }
  100%{
      opacity: 0;
      transform: rotate(45deg) translate(20px,20px);
  }
}