.projectsSection {
    width: 100% !important;
    margin-top: -50px;
}

.group {
    /* border: solid; */
}

.cards {
    /* border: solid; */
}

.cards .abouttitle {
    /* margin-top: 0px !important;
    background-color: blue; */
}

.first {
    margin-left: 15px;
}

.description {
    font-size: 12px;
    margin-top: 2px;
}


.button  {
    text-decoration: none;
    color: grey;
    border: none;
    outline: none;

}

.button:hover {
    text-decoration: none;
    color: #479B84;
    border: none;
    outline: none;
    background-color: white;
}

.second {
    margin-top: 5px;
    margin-left: 15px;
}

.footer {
    font-size: 15px;
    color: #479B84;
    margin-left: 5px;
    border: none;
    outline: none;
    background-color: white;
}

.group {
    justify-content: space-between;
    /* background-color: aqua; */
}

.card {
    border: none;
    padding: 10px;
    margin-right:20px!important;

}

.distributed {
    opacity: 100%;
    align-self: center;
    max-width: 60% !important;
    margin-top: 10px;
}

.pettin {
    opacity: 100%;
    align-self: center;
    max-width: 60% !important;
    height: 120px;
    margin-top: 10px;
}

.travel {
    opacity: 100%;
    align-self: center;
    max-width: 60% !important;
    height: 120px;
    margin-top: 10px;
}

.movie {
    opacity: 100%;
    align-self: center;
    max-width: 60% !important;
    height: 120px;
    margin-top: 10px;
}

.bookit {
    opacity: 80%;
    align-self: center;
    max-width: 60% !important;
    height: 120px;
    margin-top: 10px;
}

.quantified {
    opacity: 100%;
    align-self: center;
    max-width: 60% !important;
    height: 120px;
    margin-top: 10px;
}

/* responsive - smaller monitors */
@media (max-width: 1000px) {


  
}

/* responsive - mobile view */
@media (min-width: 375px) {
  .projectsSection {
      margin-top: 1700px;
  }
}
  
  