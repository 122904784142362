.imageme {
  opacity: 90% !important;
  border-radius: 5%;
  width: 75% !important;
}

.about {
  /* color: blue; */
  margin-top: -150px;
}

#aboutmeheader {
  /* color: blue; */
}

.tiffany {
  /* background-color: blue; */
  text-align: left;
  margin-bottom: -5px;
}

.subheader {
  /* background-color: green; */
  margin-left: -120px;
}

.skills {
  list-style-type: none;
}

.icons {
  width: 4%;
}

ul li {
  display: inline;
  margin-right: 20px;
}

.icons:hover{
  opacity: 80%;
}

/* responsive - smaller monitors */
@media (max-width: 1000px) {

  .about {
    /* color: green; */
    margin-top: 120px;
    justify-content: center;
  }

  .tiffany {
    /* background-color: aqua; */
    /* border: solid; */
    /* margin-left: 25px;
    margin-top: 8px;
    text-align: left !important; */
  }

  .imageme {
    max-width: 60%;
    /* margin-top: -160px; */
    margin-left: 40px; 
    /* opacity: 10%; */
  }

  #aboutparagraph {
    margin-left: 10px;
    text-align: justify;
  }

}

/* responsive - mobile view */
@media (min-width: 375px) {
  .about {
    padding-top: 275px;
    /* color: blue; */
  }

  .tiffany {
    /* background-color: aqua; */
    /* border: solid; */
    margin-left: 25px;
    margin-top: 8px;
    /* text-align: center; */
  }

  #aboutparagraph {
    margin-left: 10px;
    text-align: justify;
  }

  .imageme {
    width: 100%;
    margin-top: 5px;
    margin-left: 22px;
  }

  .icons {
    /* width: 5%; */
  }
}
